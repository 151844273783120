import React from "react";
import Animation from "../HomeComponents/Animation";
import ImgSlider from "./ImgSlider";

export default function ContentSlider({ CsSection_data }) {
  return (
    <section
      id={CsSection_data.id}
      className={`mt-5 ${CsSection_data.background}`}
    >
      <div className="container mt-5">
        <div>
          <header className="section-header">
            <h2>{CsSection_data.header}</h2>
            <div className="bar"></div>
          </header>
        </div>
        <div className="row">
          <div className="col-md-5 mt-3 content-slider">
            {CsSection_data.content.map((point, index) => (
              <p className="desc" key={index}>
                <div className="row">
                  <div className="col-md-1">
                    {point.icon ? <i className="bi bi-check-circle-fill"></i> : ""}

                  </div>
                  <div className="col-md-11">

                    {point.points}
                  </div>
                </div>
              </p>
            ))}
          </div>
          <div className="col-md-7 mt-5">
            <ImgSlider ImagesArray={CsSection_data.images} />
          </div>
        </div>
      </div>
      <Animation />
    </section>
  );
}
