import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import ContentSlider from "../../components/GSTTemp/ContentSlider";
import Package from "../../components/GSTTemp/Package";

export default function SoleProprietorship() {
  /* Slider */
  // const SolePropSlider = [
  //   {
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Conversion of Sole Proprietorship to Pvt Ltd Company`,
  //     content: `Apply for conversion of sole proprietorship to pvt ltd company with TODAYFILINGS experts`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag:
  //       "Best Online conversion of sole proprietorship to pvt ltd Service in Hosur",
  //   },
  //   {
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Conversion of Sole Proprietorship to Pvt Ltd Company`,
  //     content: `Best conversion of sole proprietorship to pvt ltd company registration service`,
  //     image: "/imgs/business/sliders/pvt-slide.png",
  //     alt_tag:
  //       "Best online conversion of sole proprietorship to pvt ltd company registration service near Bangalore",
  //   },

  //   {
  //     marginTop: 70,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Conversion of Sole Proprietorship to Pvt Ltd Company Registration Service in Hosur`,
  //     content: `Well popular conversion of sole proprietorship to pvt ltd company registration service in Hosur`,
  //     image: "/imgs/business/sliders/pvt.png",
  //     alt_tag:
  //       "Best online conversion of sole proprietorship to pvt ltd company registration service near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Sole Proprietorship To Pvt Company`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const SolePropAboutData = {
    header: `Conversion of Sole Proprietorship to Pvt Ltd Company`,
    sub_title: `Online sole proprietorship to Pvt Ltd company conversion with TODAYFILINGS Experts`,
    content_paragraph: [
      // `Demand for a product or service will increase as a business
      // expands, and on the flip side, it will become more
      // challenging for one individual to run and manage the
      // company. Most sole proprietorships start the process of
      // converting to private limited companies. Comparing a private
      // limited company to a single proprietorship form of business,
      // there are many advantages.`,

      `The transformation of a sole proprietorship into a private
      limited company has numerous advantages, but it also results
      in the distribution of authority and a loss of independence.
      Therefore, it is a critical choice that must be made
      carefully while taking into account all of the relevant
      elements. You must also determine whether it will actually
      benefit your expanding company.`,
    ],
  };

  /* Scroll Nav Data */
  const SolePropScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#conditions`,
      heading: `Conditions`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const SolePropIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Procedure for sole proprietorship to pvt ltd company conversion',
    image: '/imgs/registration/ngo/80g-registration.png',
    alt_tag: 'sole proprietorship to pvt ltd company conversion',
    paragraph: `To convert a sole proprietorship into a private
    limited company, first create the private limited
    company, then acquire the sole proprietorship
    through a MOA, then transfer all obligations and
    advantages to the private limited company.`,
    points: [
      {
        head: `Directors`,
        content: `Private limited
        company must have at least two directors in order to
        be incorporated. The owner or operator may serve as
        one of the directors, and any friend or relative may
        serve as the other.`,
        icon: true,
      },
      {
        head: `Director Identification Number or DIN`,
        content: `In order to be incorporated, a company's directors
        must have an Identification Number.`,
        icon: true,
      },
      {
        head: `Shareholders`,
        content: `There must be a minimum of two shareholders for the
        firm, who may also serve as directors. One of the
        directors of the private limited business can be the
        owner or proprietor of the single proprietorship.`,
        icon: true,
      },
      {
        head: `Capital`,
        content: `To launch a business, a private limited company
        needs a minimum of one lakh rupees in capital.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const SolePropCfData = {
    id: 'conditions',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Conditions for Conversion',
    content: '',
    body: [
      {
        points: `Between the single owner and private limited company,
        a sale or takeover agreement must be signed.`,
        icon: true,
      },
      {
        points: `The purpose "The takeover of a sole proprietorship"
        must be included in the sole proprietorship's
        Memorandum of Association (MOA).`,
        icon: true,
      },
      {
        points: `The sole proprietorship business must transfer all of
        its assets, obligations, and properties to the private
        limited company.`,
        icon: true,
      },
      {
        points: `The proprietor's ownership must consist of at least
        50% of the voting power and must remain the same for
        five years.`,
        icon: true,
      },
      {
        points: `Except for the number of shares held, the proprietor
        or owner does not receive any further benefits, either
        directly or indirectly.`,
        icon: true,
      },
      {
        head: `Sole Proprietorship vs. Private Limited Company`,
        points: `Sole owner is accountable for all business-related
        events, good or bad, and must cover any losses or
        debts incurred by the company. Private limited
        businesses treat the owner and the business as
        different legal entities, limiting the owner's
        liability.`,
        icon: true,
      },
      {
        points: `Because private limited corporations have access to
        fundraising opportunities, they are favored over sole
        proprietorships. In a private limited company, the
        owner or proprietor's heir designates the legitimate
        heir to manage the company's affairs.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const SolePropImages = [
    '/imgs/registration/iec/iec.png',
    '/imgs/registration/iec/iec-img.png',
    '/imgs/registration/iec/iec-img-slide.png',
  ];

  /*  Slider Content Component Data */
  const SolePropSCData = {
    id: 'benefits',
    background: [],
    mt_div: 'mt-3',
    header: `Private Limited Company Advantages in India`,
    content: [
      {
        points: `Credit is accessible Both the stockholders and the
        holders of the unsecured bond may contribute money to
        a private limited corporation.`,
        icon: true,
      },
      {
        points: `Execute globally Private limited corporations
        encourage foreign direct investment, whereas other
        forms of businesses require the required license,
        liaison, and administration recognition for
        international investment.`,
        icon: true,
      },
      {
        points: `Distinct legal entity A private limited business is
        regarded as a distinct legal person with full standing
        and access to courts. It behaves like a fake person
        and has the ability to acquire any assets or real
        estate in its own name.`,
        icon: true,
      },
    ],
    images: SolePropImages,
  };

  /*  Content Img Component Data */
  const SolePropCiData = {
    id: 'documents',
    background: 'main-content',
    mt_div: 'mt-3',
    header: `Documents Required for Conversion of Sole
    Proprietorship to Private Limited Company`,
    paragraph: ``,
    points: [
      {
        content: `Copy of each director's PAN serves as identification.`,
        icon: true,
      },
      {
        content: `Address Proof - Copy of Aadhar card/ Voters ID of all
        directors.`,
        icon: true,
      },
      {
        content: `Evidence of the owner of a registered business
        location (if owned).`,
        icon: true,
      },
      {
        content: `If the registered business location is a rented space,
        a rental agreement.`,
        icon: true,
      },
      {
        content: `Landlord's NOC, or No Objection Certificate.`,
        icon: true,
      },
      {
        content: `Utility bills include water and electricity.`,
        icon: true,
      },
      {
        head: `Documentation that must be presented to the MCA`,
        content: `Along with the firm's MOA, AOA, and other documents,
        Form 1 must be filed`,
        icon: true,
      },
      {
        content: `Utility bills include water and electricity.`,
        icon: true,
      },
      {
        content: `Information regarding the registered office is
        provided on Form 18.`,
        icon: true,
      },
      {
        content: `Details on the directors is provided in Form 32.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/iec/iec-img.png',
    alt_tag: 'Benefits for Iec Registration',
  };

  var FAQ_data = [
    {
      header: "Where is the company's registered office?",
      body: [
        {
          content: `The applicant may submit an application under "RUN" by putting up
              two alternate names and their implications.`,
          icon: false,
        },
      ],
    },

    {
      header: 'How long is the procedure of forming a company still in effect?',
      body: [
        {
          content: `As long as the annual compliances are timely submitted, a company
              that has been incorporated will continue to exist and be active.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How long may the newly incorporated Private Limited Company coexist with the existing Sole Proprietorship?',
      body: [
        {
          content: `The proprietorship business must be dissolved within 90 days or
              three months of the Limited Company's incorporation.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What are the most important considerations when forming a private limited company?',
      body: [
        {
          content: `A Pvt. Ltd. corporation would need at least two members who would
              serve as the company's directors.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who is eligible to join a Private Limited Company?',
      body: [
        {
          content: `Any person or organization, including one that is foreign, may
              join a Private Limited Company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Can I convert my sole proprietorship to a private limited company and keep my licenses ?',
      body: [
        {
          content: `The permissions and licenses cannot be transferred from a Sole
              Proprietorship to a Private Limited Company.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Is it necessary to get a Proprietorship audited?',
      body: [
        {
          content: `In the case of proprietorships tax audit is not necessary, it is completely
           based on the turnover and other criteria.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the major tax benefits for a sole proprietor?',
      body: [
        {
          content: `The main tax advantage of a proprietorship is that it can 
          deduct the cost of health insurance for self, spouse, and dependents.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Is a Proprietorship taxed twice or double?',
      body: [
        {
          content: `As the sole proprietorships are not considered tax entities, they are not separate from 
          their owners so the proprietor does not have to face double taxation.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to file income tax returns for a Proprietorship?',
      body: [
        {
          content: `Proprietorship annual return filing is done through ITR`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Conversion of Sole Proprietorship to Pvt Ltd Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={SolePropSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={SolePropAboutData} />

          <Counter />
          <ScrollNav scroll_data={SolePropScrollId} />

          <ImgContent item={SolePropIcData} />

          <ContentForm CFSection_data={SolePropCfData} />

          <ContentSlider CsSection_data={SolePropSCData} />

          <ContentImg CISection_data={SolePropCiData} />

          <Cta />
          <Guidance />
          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
